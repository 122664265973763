import { combineReducers } from "redux";
import cannedMessages from "./Reducers/Reducers";
import PersonalCannedMessages from "./Reducers/Reducers";
import resources from "./Reducers/Reducers";
import allCannedMessages from "./Reducers/Reducers"


const appReducers = combineReducers({
  cannedMessages,
  PersonalCannedMessages,
  resources,
  allCannedMessages
});

export default (state, action) => {
  return appReducers(state, action);
};
