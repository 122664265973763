import { ActionTypes } from "../Types/types";

export default (
    state = {
        cannedMessages: [],
        personalCannedMessages: [],
        allCannedMessages:[]
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.CANNED_MESSAGES:
            // return {cannedMessages:[...state.cannedMessages,...action.payload]};
            return { ...state, cannedMessages: action.payload }
        case ActionTypes.PERSONAL_CANNED_MESSAGES:
            return {
                ...state,
                personalCannedMessages: action.payload,
            };
        case ActionTypes.RESOURCES:
            return {
                ...state,
                resources: action.payload,
            };
        case ActionTypes.ALL_CANNED_MESSAGES:
            return {
                ...state,
                allCannedMessages: action.payload,
            };
        default:
            return { ...state };
    }
};
